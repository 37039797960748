export default {
  SECTION: 'answer',
  MODAL_EXAM_HISTORY: 'modal:history:exam',
  MODAL_EXAM_HISTORY_GENERAL: 'modal:history:exam:overview',
  MODAL_HISTORY_INPUT_NUMBER: 'modal:history:input:number',
  MODAL_HISTORY_INPUT_SELECT: 'modal:history:input:select',
  MODAL_HISTORY_INPUT_SCORES: 'modal:history:input:scores',
  MODAL_HISTORY_INPUT_RADIO: 'modal:history:input:radio',
  MODAL_HISTORY_INPUT_CHECKBOX_GROUP: 'modal:history:input:checkbox_group',
  MODAL_HISTORY_INPUT_CHECK_ONLY: 'modal:history:input:checkbox_group',
  MODAL_HISTORY_PAIN_MAP_BODY: 'modal:history:pain_map_body',

  FORMS_ANSWER_SET_FIELDS_ERROR: 'FORMS_ANSWER_SET_FIELDS_ERROR',
  FORMS_ANSWER_SET_NAME_CONTEXT: 'FORMS_ANSWER_SET_NAME_CONTEXT',
  FORMS_ANSWER_SET_FORM_INITIAL: 'FORMS_ANSWER_SET_FORM_INITIAL',

  FORMS_ANSWER_SET_FORM_BUILD: 'FORMS_ANSWER_SET_FORM_BUILD',
  FORMS_ANSWER_SET_FIELDS_FORM: 'FORMS_ANSWER_SET_FIELDS_FORM',

  FORMS_ANSWER_GET_FORM_BY_ID_FETCH_REQUEST:
    'FORMS_ANSWER_GET_FORM_BY_ID_FETCH_REQUEST',
  FORMS_ANSWER_GET_FORM_BY_ID_FETCH_SUCCESS:
    'FORMS_ANSWER_GET_FORM_BY_ID_FETCH_SUCCESS',
  FORMS_ANSWER_GET_FORM_BY_ID_FETCH_ERROR:
    'FORMS_ANSWER_GET_FORM_BY_ID_FETCH_ERROR',

  FORMS_ANSWER_UPDATE_FIELD_BY_ID: 'FORMS_ANSWER_UPDATE_FIELD_BY_ID',

  FORMS_ANSWER_UPDATE_FIELD_PATIENT_BY_ID:
    'FORMS_ANSWER_UPDATE_FIELD_PATIENT_BY_ID',

  FORMS_ANSWER_SAVE_FORMS: 'FORMS_ANSWER_SAVE_FORMS',

  FORMS_ANSWER_GET_SEARCH_FETCH_REQUEST:
    'FORMS_ANSWER_GET_SEARCH_FETCH_REQUEST',
  FORMS_ANSWER_GET_SEARCH_FETCH_SUCCESS:
    'FORMS_ANSWER_GET_SEARCH_FETCH_SUCCESS',
  FORMS_ANSWER_GET_SEARCH_FETCH_ERROR: 'FORMS_ANSWER_GET_SEARCH_FETCH_ERROR',
  FORMS_ANSWER_SET_RESET_SEARCH: 'FORMS_ANSWER_SET_RESET_SEARCH',

  FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_REQUEST:
    'FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_REQUEST',
  FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_SUCCESS:
    'FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_SUCCESS',
  FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_ERROR:
    'FORMS_ANSWER_CREATE_PROFESSIONAL_FETCH_ERROR',

  FORMS_ANSWER_CREATE_PATIENT_FETCH_REQUEST:
    'FORMS_ANSWER_CREATE_PATIENT_FETCH_REQUEST',
  FORMS_ANSWER_CREATE_PATIENT_FETCH_SUCCESS:
    'FORMS_ANSWER_CREATE_PATIENT_FETCH_SUCCESS',
  FORMS_ANSWER_CREATE_PATIENT_FETCH_ERROR:
    'FORMS_ANSWER_CREATE_PATIENT_FETCH_ERROR',

  FORMS_ANSWER_EXAM_FETCH_REQUEST: 'FORMS_ANSWER_EXAM_FETCH_REQUEST',
  FORMS_ANSWER_EXAM_FETCH_SUCCESS: 'FORMS_ANSWER_EXAM_FETCH_SUCCESS',
  FORMS_ANSWER_EXAM_FETCH_ERROR: 'FORMS_ANSWER_EXAM_FETCH_ERROR',
  FORMS_ANSWER_SET_SEARCH_EXAM_RESET: 'FORMS_ANSWER_SET_SEARCH_EXAM_RESET',

  FORMS_ANSWER_EXERCISE_FETCH_REQUEST: 'FORMS_ANSWER_EXERCISE_FETCH_REQUEST',
  FORMS_ANSWER_EXERCISE_FETCH_SUCCESS: 'FORMS_ANSWER_EXERCISE_FETCH_SUCCESS',
  FORMS_ANSWER_EXERCISE_FETCH_ERROR: 'FORMS_ANSWER_EXERCISE_FETCH_ERROR',
  FORMS_ANSWER_SET_SEARCH_EXERCISE_RESET:
    'FORMS_ANSWER_SET_SEARCH_EXERCISE_RESET',

  FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_REQUEST:
    'FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_REQUEST',
  FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_SUCCESS:
    'FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_SUCCESS',
  FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_ERROR:
    'FORMS_ANSWER_ADD_EXAM_DYNAMIC_FETCH_ERROR',

  FORMS_ANSWER_SET_ERROR_FIELD_BY_ID: 'FORMS_ANSWER_SET_ERROR_FIELD_BY_ID',
  FORMS_ANSWER_SET_ERROR_FIELD_PATIENT: 'FORMS_ANSWER_SET_ERROR_FIELD_PATIENT',

  FORMS_ANSWER_ON_CHANGE_REPORT: 'FORMS_ANSWER_ON_CHANGE_REPORT',
  FORMS_ANSWER_SET_NEW_REPORT_BY_ID: 'FORMS_ANSWER_SET_NEW_REPORT_BY_ID',
  FORMS_ANSWER_SET_REMOVE_REPORT_BY_ID: 'FORMS_ANSWER_SET_REMOVE_REPORT_BY_ID',

  FORMS_ANSWER_SET_STATUS_BAR_MESSAGE: 'FORMS_ANSWER_SET_STATUS_BAR_MESSAGE',

  FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_REQUEST:
    'FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_REQUEST',
  FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_SUCCESS:
    'FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_SUCCESS',
  FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_ERROR:
    'FORMS_ANSWER_GET_HISTORY_EXAM_FETCH_ERROR',

  FORMS_ANSWER_SET_ENABLE_HOUR_TABLE: 'FORMS_ANSWER_SET_ENABLE_HOUR_TABLE',

  FORMS_ANSWER_SET_URL_IMAGE_GRAPHIC: 'FORMS_ANSWER_SET_URL_IMAGE_GRAPHIC',
  FORMS_ANSWER_FETCH_REQUEST_IMAGE_GRAPHIC: 'FORMS_ANSWER_FETCH_REQUEST_IMAGE_GRAPHIC',
  FORMS_ANSWER_FETCH_ERROR_IMAGE_GRAPHIC: 'FORMS_ANSWER_FETCH_ERROR_IMAGE_GRAPHIC',
  FORMS_ANSWER_UPDATE_FIELD_BY_FIELD_ID: 'FORMS_ANSWER_UPDATE_FIELD_BY_FIELD_ID',

  FORMS_ANSWER_SET_IS_FIELD_EMPTY_FORM: 'FORMS_ANSWER_SET_IS_FIELD_EMPTY_FORM',
  SET_TOKEN_ICD: 'SET_TOKEN_ICD',

  GET_HISTORY_FIELDS_BY_FORM_FETCH_REQUEST: 'GET_HISTORY_FIELDS_BY_FORM_FETCH_REQUEST',
  GET_HISTORY_FIELDS_BY_FORM_FETCH_SUCCESS: 'GET_HISTORY_FIELDS_BY_FORM_FETCH_SUCCESS',
  GET_HISTORY_FIELDS_BY_FORM_FETCH_ERROR: 'GET_HISTORY_FIELDS_BY_FORM_FETCH_ERROR',

  MODAL_DOWNLOAD_IMAGE_GRAPHIC: 'MODAL_DOWNLOAD_IMAGE_GRAPHIC',

  CHANGE_CID_SELECTED: 'CHANGE_CID_SELECTED',
  
  GET_PAIN_MAP_BODY_AREA_HISTORY_REQUEST: 'GET_PAIN_MAP_BODY_AREA_HISTORY_REQUEST',
  GET_PAIN_MAP_BODY_AREA_HISTORY_SUCCESS: 'GET_PAIN_MAP_BODY_AREA_HISTORY_SUCCESS',
  GET_PAIN_MAP_BODY_AREA_HISTORY_ERROR: 'GET_PAIN_MAP_BODY_AREA_HISTORY_ERROR',
};
